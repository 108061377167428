/* eslint-disable */
import { UserModule } from '@/store/user';
import { OtherModule } from '@/store/other';

export type pay_type = 'card' | 'trans' | 'phone';

export const pay = (
  amount: number,
  pay_method: pay_type,
  other: Object = {},
  name?: string,
  naverUseCfm?: string
) => {
  const cpid = 'A010011720';
  const pg = {
    phone: `danal.${cpid}`,
    trans: `danal_tpay.${cpid}`,
    card: `danal_tpay.${cpid}`,
    // naverpay:'naverpay',
  }[pay_method];
  return new Promise((resolve, reject) => {
    try {
      const data = {
        name: '',
        buyer_email: UserModule.info?.email,
        buyer_name: UserModule.info?.real_name,
        buyer_tel: UserModule.info?.phone,
        buyer_addr: UserModule.info?.address_detail,
        buyer_postcode: UserModule.info?.address_code,
        app_scheme: 'tongtong.edu',
        pg,
        merchant_uid: 'tongtong_' + new Date().getTime(),
        // naverUseCfm,
        // naverUseCfm: `${new Date().getFullYear()}${new Date().getMonth()+1 < 10 ? '0' + (new Date().getMonth()+1): new Date().getMonth()+1}${new Date().getDate() < 10 ? '0'+new Date().getDate():new Date().getDate()}`,
        m_redirect_url: 'https://tongtongedu.com/other/pay/success',
        ...other,
        amount,
        pay_method,
        // naverPopupMode:false,
        // naverProducts:[
        // 	{
        // 		"categoryType": "ETC",
        // 		"categoryId": "ETC",
        // 		"uid": "imp07867187",
        // 		"name": name,//读书会name
        // 		"count": 1
        // 	}
        // ]
      };
      console.log(data);
      if (!OtherModule.is_mobile) {
        (document.getElementsByClassName('imp-frame')[0] as any).style.paddingTop = '100px';
      }
      IMP.request_pay(data, (res: any) => {
        if (res.success) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const naverpay = (
  amount: number,
  name: string,
  naverUseCfm: string,
  submit_obj: string,
  pay_type: 'active' | 'club',
  naverCultureBenefit = false,
  id: number | string,
  useCfmYmdt: string,
  categoryType: string,
  categoryId: string,
  taxType: string,
  price: number
) => {
  return new Promise((resolve, reject) => {
    let data: any = {
      name,
      buyer_email: UserModule.info?.email,
      buyer_name: UserModule.info?.real_name,
      buyer_tel: UserModule.info?.phone,
      buyer_addr: UserModule.info?.address_detail,
      buyer_postcode: UserModule.info?.address_code,
      app_scheme: 'switch.changbi',
      pg: 'naverpay',
      merchant_uid: 'switch_' + new Date().getTime(),
      naverUseCfm,
      m_redirect_url: `https://switch.changbi.com/other/naverpay?submit_obj=${submit_obj}&type=${pay_type}`,
      amount,
      pay_method: 'naverpay',
      naverPopupMode: false,
      naverProducts: [
        {
          categoryType: categoryType,
          categoryId: categoryId,
          uid: id,
          name: name,
          count: 1,
        },
      ],
      naverCultureBenefit,
      useCfmYmdt,
      taxType,
    };
    if (taxType === 'TAX FREE') data.tax_free = amount;
    console.log(data);
    IMP.request_pay(data, (res: any) => {
      if (res.success) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  });
};
