





















































































































































































































/* eslint-disable */
import { subject, api_subject, api_user, position } from '@/api';
import { naverpay, pay, pay_type } from '@/util/pay';
import { Vue, Component, Watch } from 'vue-property-decorator';
import Bread from '@/components/bread/index.vue';
import Inner from '@/components/inner/index.vue';
import { UserModule } from '@/store/user';
import { Encryption } from '@/util/encryption';
import { winopen } from '@/util/other';
import { numFormat } from '@/util/string';
import { OtherModule } from '@/store/other';

@Component({
  components: {
    Inner,
    Bread,
  },
})
export default class extends Vue {
  numFormat = numFormat;
  bread = [
    {
      to: `/subject/0/list`,
      title: '응시직렬',
    },
    {
      to: `/subject/0/list`,
      title: ``,
    },
  ];
  price: string | number = '';
  index = '';
  resultPrice = 0;
  info: any = {
    id: 0,
    title: '',
    cover: '',
    position: { id: 0, title: '' },
    chapter: 0,
    content: [],
    examination: 0,
    user: null,
  };

  get id(): number {
    return +this.$route.params.id;
  }

  get position_id(): number {
    return +this.$route.params.position_id;
  }

  // set id(id: number) {
  //   this.$router.push(`/active/${this.active_type}/info/${id}`);
  // }

  get is_mobile() {
    return OtherModule.is_mobile;
  }

  changeSelect(e: any) {
    this.index = e;
    this.price = +this.info.content[e].price;
  }

  @Watch('id', { immediate: true })
  async get_info() {
    this._loading = true;
    this.info = await api_subject.get_info(this.id).finally(() => {
      this._loading = false;
    });
    this.bread = [
      {
        to: `/subject/0/list`,
        title: '응시직렬',
      },
      {
        to: `/subject/${this.position_id}/list`,
        title: this.info.position.title,
      },
      {
        to: '',
        title: this.info.title,
      },
    ];
  }

  @Watch('score', { immediate: true })
  watch_score(e: any) {
    if (this.price) {
      this.resultPrice = Number(this.price) - this.score > 0 ? Number(this.price) - this.score : 0;
    } else {
      this.resultPrice = 0;
    }
  }

  @Watch('price', { immediate: true })
  watch_price(e: any) {
    let num = +e;
    if (num - this.score < 0) {
      this.resultPrice = 0;
    } else {
      this.resultPrice = num - this.score;
    }
  }

  @Watch('resultPrice', { immediate: true })
  watach_resultPrice(newVal: any, oldVal: any) {
    if (newVal === 0) {
      this.pay_type = '';
    }
  }

  changeScore(e: any) {
    let num = +e;
    if (num < 0) return (this.score = 0);
    this.score = num;
  }

  key = false;
  apply_reason = '';
  dialog_loading = false;

  close() {
    this.apply_reason = '';
    this.key = false;
  }

  check() {}

  pay_type: pay_type | '' = '';
  score = 0;

  async pay() {
    if (!this.info.user?.id) {
      // to login
      console.log(123);
    }
    if (this.price === '') {
      await this.$confirm('옵션을 선택하지 않으셨습니다. 옵션을 선택해주세요.', {
        confirmButtonText: '확인',
        cancelButtonText: '취소하기',
      });
      return;
    }
    if (this.score > this.info.user?.score) {
      await this.$confirm('보유한 포인트 보다 사용 포인트가 더 큽니다. 다시 한번 확인해주세요.', {
        confirmButtonText: '확인',
        cancelButtonText: '취소하기',
      });
      return;
    }
    if (Number(this.price) < this.score) {
      await this.$confirm('상품금액 보다 사용 포인트가 클 수 없습니다. 다시 한번 확인해주세요.', {
        confirmButtonText: '확인',
        cancelButtonText: '취소하기',
      });
      return;
    }

    /**
     * 재결제 가능 여부 판단하는 로직 주석 처리
     *
     * 사용자는 이용한 과목의 이용기관 만료 여부와 상관 없이 재결제를 할 수 있다.
     * 사용자는 결제한 적 있는 옵션(이용기간)과 무관하게 재결제를 할 수 있다.
     * = 따라서 사용자는 이전 결제 이력과 무관하게 어떤 경우에든 재결제를 할 수 있다.
     * = 재결제 가능 여부를 판단하는 로직을 거칠 필요가 없다.
     */
    // await api_subject.check_info({
    //   subject: this.info.id,
    //   index: this.index,
    // });

    this._loading = true;
    let data: any = {
      score: this.score,
      index: this.index,
      subject: this.info.id,
      merchant_uid: '',
    };
    sessionStorage.setItem('somePrice', this.price + '');
    sessionStorage.setItem('historyUrl', this.$route.path);
    // OtherModule.set_route(this.$route.path)
    // if(this.pay_type === 'naverpay'){
    // 	const dd = this.info.pay_type === 0 ? new Date(new Date(this.info.apply_end.replace(/\./g,'-')).getTime()) : new Date(new Date().getTime() + 14 * 86400000)
    // 	return naverpay(this.price,this.info.title,
    // 	`${dd.getFullYear()}${dd.getMonth()+1 < 10 ? '0'+ (dd.getMonth() + 1) : dd.getMonth()+1}${dd.getDate()<10?'0'+(dd.getDate()):dd.getDate()}`,
    // 	JSON.stringify(data),'active',this.info.pay_type===1?true:false,this.info.isbn_code === 1 ?this.info.isbn_content:this.info.id,`${dd.getFullYear()}${dd.getMonth()+1 < 10 ? '0'+ (dd.getMonth() + 1) : dd.getMonth()+1}${dd.getDate()<10?'0'+(dd.getDate()):dd.getDate()}`,this.info.pay_type===0?'ETC':'BOOK',this.info.pay_type===0?'ETC':'GENERAL',this.info.pay_type===0?'TAX':'TAX FREE',this.info.price)
    // }
    if (this.resultPrice === 0) {
      // delete data.merchant_uid;
      api_subject
        .pay_join(data)
        .then(() => {
          this.$router.push({
            path: '/other/pay/success',
            query: {},
          });
        })
        .catch((e) => {
          this.$router.push({
            path: '/other/pay/fail',
            query: {
              go_1: this.$route.path,
              go_2: `/subject/${this.position_id}/list`,
              fail_msg: e?.error_msg,
            },
          });
        });
      return;
    }
    if (this.pay_type === '') {
      this._loading = false;
      await this.$confirm('결제방법을 선택하지 않으셨습니다. 결제방법을 선택해주세요.', {
        confirmButtonText: '확인',
        cancelButtonText: '취소하기',
      });
      return;
    }
    pay(this.resultPrice, this.pay_type, { name: this.info.title })
      .then((res: any) => {
        console.log('success', res);
        const merchant_uid: string = res.merchant_uid;
        data.merchant_uid = merchant_uid;
        api_subject.pay_join(data).then(() => {
          this.$router.push({
            path: '/other/pay/success',
            query: {},
          });
        });
      })
      .catch((e) => {
        console.log('fail', e);
        this.$router.push({
          path: '/other/pay/fail',
          query: {
            go_1: this.$route.path,
            go_2: `/subject/${this.position_id}/list`,
            fail_msg: e?.error_msg,
          },
        });
      })
      .finally(() => {
        this._loading = false;
      });
  }

  // get show_naverpay(){
  // 	return UserModule.info?.id === 4
  // }
}
